const externalRoutes = {
  app: `${process.env.GATSBY_APP_URL}`,
  api: `${process.env.GATSBY_SERVER_URL}`,
  facebook: `${process.env.GATSBY_FACEBOOK_URL}`,
  instagram: `${process.env.GATSBY_INSTAGRAM_URL}`,
  youtube: `${process.env.GATSBY_YOUTUBE_URL}`,
  tiktok: `${process.env.GATSBY_TIKTOK_URL}`,
  googlePlay: `${process.env.GATSBY_GOOGLE_PLAY_URL}`,
  appStore: `${process.env.GATSBY_APP_STORE_URL}`,
  teletherapyFormUrl:
    'https://docs.google.com/forms/d/e/1FAIpQLSd3538HkRZ438iWPclG6_B2IOSF6uGpzE6KPTuKiBLTNW9R9w/viewform',
  teletherapyPhoneCallFormUrl: 'https://www.bit.ly/logoped-poziv',
  academyFormUrl: 'https://forms.gle/huj7EzW9p9L7Ue5R6',
  academyInstitutionsFormUrl: 'https://forms.gle/ipD3afwj9ecoy4f86',
};

export default externalRoutes;
