const routes = {
  home: '/',
  kokolingoEkspert: '/kokolingo-ekspert',
  academy: '/akademija-za-predskolarce',
  academyInstitutions: '/akademija-za-ustanove',
  blog: '/edukativni-kutak',
  pricing: '/cjenik',
  faq: '/ucestala-pitanja',
  termsOfService: '/uvjeti-kupnje',
  privacyPolicy: '/izjava-o-privatnosti',
  termsOfServiceMobile: '/mobilna-aplikacija/uvjeti-kupnje',
  privacyPolicyMobile: '/mobilna-aplikacija/izjava-o-privatnosti',
};

export default routes;
